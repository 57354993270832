<template>
  <div class="sign-success-page">
    <div class="icon">
      <SvgIcon type="launch_success" class="launch_success" />
      <div class="title">签署完成</div>
    </div>
    <ul class="info-table">
      <li
        v-for="(item, index) in labelList"
        :key="index"
        :class="{ bg: index % 2 !== 0 }"
      >
        <span class="label">{{ item.label }}</span>
        <span
          :class="
            index === 0 && info.launchSigner.length > 20 ? 'value2' : 'value1'
          "
          >{{ info[item.value] }}</span
        >
      </li>
    </ul>
    <div class="button-group">
      <van-button
        v-if="info.businessStatus === 2 || info.businessStatus === 7"
        plain
        type="primary"
        @click="handleToDownload"
      >
        下载合同
      </van-button>
      <van-button type="primary" plain @click="handleToDetail"
        >查看签署详情</van-button
      >
      <van-button type="primary" plain @click="handleToHome"
        >返回列表</van-button
      >
    </div>
    <div class="followForm" v-if="codeShow">
      <div class="detail">
        <div>长按保存二维码</div>
        <div>或微信搜索关注「{{ systemInfo.company }}」</div>
        <div>方便后续查看签署文件</div>
      </div>
      <div class="code">
        <img :src="systemInfo.qrcode" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { createSimpleLaunch } from '@/model/launch'
  import { Dialog, Toast } from 'vant'
  import contractSignApis from '@/api/contractSign'
  import { isWeiXin } from '@/utils/ua'
  import moment from 'moment'

  export default {
    name: 'signSuccess',
    data() {
      return {
        businessId: undefined,
        defaultName: null,
        openId: window.sessionStorage.openId
          ? window.sessionStorage.openId
          : null,
        labelList: [
          { label: '发起人：', value: 'launchSigner' },
          { label: '签署标题：', value: 'title' },
          { label: '发起时间：', value: 'launchTime' },
          { label: '签署时间：', value: 'finishTime' },
          { label: '存证机构：', value: 'address' },
        ],
        info: { launchSigner: '' },
        codeShow: false,
        copyUrl: null,
      }
    },
    created() {
      this.codeShow = !isWeiXin()
      this.getQuery()
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    methods: {
      getQuery() {
        this.businessId = this.$route?.query?.businessId
        this.defaultName = this.$route?.query?.defaultName
        this.getSignResult()
      },
      async getSignResult() {
        try {
          const model = {
            businessId: this.businessId,
            defaultName: this.defaultName,
          }
          const {
            finishTime,
            launchSigner,
            launchTime,
            title,
            businessStatus,
            businessId,
          } = await contractSignApis.getSignResult(model)
          this.info = {
            address: '北京海诚公证处',
            launchSigner,
            title,
            businessStatus,
            businessId,
            finishTime: finishTime
              ? moment(finishTime * 1000).format('YYYY/MM/DD HH:mm:ss')
              : '',
            launchTime: launchTime
              ? moment(launchTime * 1000).format('YYYY/MM/DD HH:mm:ss')
              : '',
          }
        } catch (error) {
          console.error(error)
        }
      },
      handleToDetail() {
        this.$router.push({
          name: 'SignDetail',
          query: {
            businessId: this.businessId,
            fileStatus: this.info.businessStatus,
          },
        })
      },
      handleToHome() {
        this.$router.replace({
          path: '/list',
        })
      },
      handleToDownload() {
        try {
          contractSignApis
            .getDownloadEvidenceUrl({
              businessId: this.businessId,
            })
            .then(res => {
              this.copyUrl = res
              if (isWeiXin()) {
                Dialog.confirm({
                  title: '下载合同',
                  message: '复制链接到浏览器打开，然后下载',
                  confirmButtonText: '复制链接',
                })
                  .then(() => {
                    this.$copyText(this.copyUrl)
                    this.$toast({
                      message:
                        '合同链接已复制成功，请前往浏览器打开，推荐使用百度，某些浏览器下载后可能会出现打不开的问题',
                    })
                  })
                  .catch(() => {
                    // on cancel
                  })
                return
              }
              Dialog.confirm({
                title: '提示',
                message: '您要下载《签署标题》的文件吗？',
              })
                .then(() => {
                  const link = document.createElement('a')
                  link.href = this.copyUrl
                  link.click()
                })
                .catch(() => {
                  // on cancel
                })
            })
        } catch (error) {
          console.error(error)
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .sign-success-page {
    height: 100vh;
    background: #fff;
    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      .launch_success {
        font-size: 60px;
        margin-bottom: 4px;
      }
      .title {
        font-size: 16px;
        color: #111a34;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .text {
        margin-top: 4px;
        color: #677283;
        font-size: 14px;
        font-weight: 500;
      }
      .tip {
        margin-top: 32px;
        font-size: 14px;
        color: #111a34;
      }
    }
    .info-table {
      font-size: 12px;
      color: #1f2023;
      padding: 0 90px;
      li {
        // height: 24px;
        line-height: 24px;
        margin-bottom: 6px;
        display: flex;
        span {
          display: inline-block;
        }
        .label {
          width: 65px;
        }
        .value {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .value2 {
          flex: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          white-space: normal;
        }
      }
    }
    .button-group {
      margin-top: 27px;
      padding: 0 48px;
      .van-button {
        width: 100%;
        height: 45px;
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        margin-bottom: 16px;
      }
    }
    .followForm {
      height: 100px;
      padding: 0 48px;
      background: #f4f5f6;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .code {
        width: 72px;
        height: 72px;
        border: 1px solid #1676ff;
        border-radius: 4px;
        padding: 2px;
        img {
          width: 100%;
        }
      }
      .detail {
        width: 144px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #5f6368;
        line-height: 24px;
      }
    }
  }
</style>
